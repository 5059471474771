var Fireworks = function(){
  /*=============================================================================*/	
  /* Utility
  /*=============================================================================*/
  var self = this;
  var rand = function(rMi, rMa){return ~~((Math.random()*(rMa-rMi+1))+rMi);}
  if (!window.requestAnimFrame) window.requestAnimFrame=function(){return window.requestAnimationFrame||window.webkitRequestAnimationFrame||window.mozRequestAnimationFrame||window.oRequestAnimationFrame||window.msRequestAnimationFrame||function(a){window.setTimeout(a,1E3/60)}}();

  /*=============================================================================*/	
  /* Initialize
  /*=============================================================================*/
  self.init = function(){	
    self.dt = 0;
    self.oldTime = Date.now();
    self.canvas = document.createElement('canvas');				
    self.canvasContainer = $('#fireworks-container');

    self.canvas.width = self.cw = window.innerWidth;
    self.canvas.height = self.ch = window.outerHeight;	

    self.particles = [];	
    self.fireworks = [];	

    self.fworkSpeed = 1;
    self.fworkAccel = 2;

    self.partCount = 50;
    self.partSpeed = 10;
    self.partSpeedVariance = 0;
    self.partWind = 0;
    self.partFriction = 7;
    self.partGravity = 2;
    self.flickerDensity = 20;

    self.lineWidth = 2;
    self.clearAlpha = 45;

    self.mx = self.cw/2;
    self.my = self.ch/2;
    self.currentColor = '';

    self.canvasContainer.append(self.canvas);
    self.ctx = self.canvas.getContext('2d');
    self.ctx.lineCap = 'round';
    self.ctx.lineJoin = 'round';

    //self.bindEvents();			
    self.canvasLoop();
  };		

  /*=============================================================================*/	
  /* Particle Constructor
  /*=============================================================================*/
  var Particle = function(x, y, color){
    this.x = x;
    this.y = y;
    this.coordLast = [
      {x: x, y: y},
      {x: x, y: y},
      {x: x, y: y}
    ];
    this.angle = rand(0, 360);
    this.speed = rand(((self.partSpeed - self.partSpeedVariance) <= 0) ? 1 : self.partSpeed - self.partSpeedVariance, (self.partSpeed + self.partSpeedVariance));
    this.friction = 1 - self.partFriction/100;
    this.gravity = self.partGravity/2;
    this.color = color;
    this.brightness = rand(50, 80);
    this.alpha = rand(40,100)/100;
    this.decay = rand(10, 50)/1000;
    this.wind = (rand(0, self.partWind) - (self.partWind/2))/25;
    this.lineWidth = self.lineWidth;
  };

  Particle.prototype.update = function(index){
    var radians = this.angle * Math.PI / 180;
    var vx = Math.cos(radians) * this.speed;
    var vy = Math.sin(radians) * this.speed + this.gravity;
    this.speed *= this.friction;

    this.coordLast[2].x = this.coordLast[1].x;
    this.coordLast[2].y = this.coordLast[1].y;
    this.coordLast[1].x = this.coordLast[0].x;
    this.coordLast[1].y = this.coordLast[0].y;
    this.coordLast[0].x = this.x;
    this.coordLast[0].y = this.y;

    this.x += vx * self.dt;
    this.y += vy * self.dt;

    this.angle += this.wind;				
    this.alpha -= this.decay;

    if(this.alpha < .05){					
      self.particles.splice(index, 1);	
    }			
  };

  Particle.prototype.draw = function(){
    var coordRand = (rand(1,3)-1);
    self.ctx.beginPath();								
    self.ctx.moveTo(Math.round(this.coordLast[coordRand].x), Math.round(this.coordLast[coordRand].y));
    self.ctx.lineTo(Math.round(this.x), Math.round(this.y));
    self.ctx.closePath();			
    self.ctx.strokeStyle = this.color;
    self.ctx.stroke();

    if(self.flickerDensity > 0){
      var inverseDensity = 50 - self.flickerDensity;					
      if(rand(0, inverseDensity) === inverseDensity){
        self.ctx.beginPath();
        self.ctx.arc(Math.round(this.x), Math.round(this.y), rand(this.lineWidth,this.lineWidth+3)/2, 0, Math.PI*2, false)
        self.ctx.closePath();
        var randAlpha = rand(50,100)/100;
        self.ctx.fillStyle = this.color;
        self.ctx.fill();
      }	
    }
  };

  /*=============================================================================*/	
  /* Create Particles
  /*=============================================================================*/
  self.createParticles = function(x,y, color){
    var countdown = self.partCount;
    while(countdown--){						
      self.particles.push(new Particle(x, y, color));
    }
  };

  /*=============================================================================*/	
  /* Update Particles
  /*=============================================================================*/		
  self.updateParticles = function(){
    var i = self.particles.length;
    while(i--){
      var p = self.particles[i];
      p.update(i);
    };
  };

  /*=============================================================================*/	
  /* Draw Particles
  /*=============================================================================*/
  self.drawParticles = function(){
    var i = self.particles.length;
    while(i--){
      var p = self.particles[i];				
      p.draw();				
    };
  };

  /*=============================================================================*/	
  /* Firework Constructor
  /*=============================================================================*/
  var Firework = function(startX, startY, targetX, targetY, color, speed){
    this.x = startX;
    this.y = startY;
    this.startX = startX;
    this.startY = startY;
    this.hitX = false;
    this.hitY = false;
    this.coordLast = [
      {x: startX, y: startY},
      {x: startX, y: startY},
      {x: startX, y: startY}
    ];
    this.targetX = targetX;
    this.targetY = targetY;
    this.speed = self.fworkSpeed * speed;
    this.angle = Math.atan2(targetY - startY, targetX - startX);
    this.shockwaveAngle = Math.atan2(targetY - startY, targetX - startX)+(90*(Math.PI/180));
    this.acceleration = (self.fworkAccel * speed)/100;
    this.color = color;
    this.brightness = rand(50, 80);
    this.alpha = rand(50,100)/100;
    this.lineWidth = self.lineWidth;
    this.targetRadius = 1;
  };

  Firework.prototype.update = function(index){
    self.ctx.lineWidth = this.lineWidth;

    vx = Math.cos(this.angle) * this.speed,
      vy = Math.sin(this.angle) * this.speed;
    this.speed *= 1 + this.acceleration;				
    this.coordLast[2].x = this.coordLast[1].x;
    this.coordLast[2].y = this.coordLast[1].y;
    this.coordLast[1].x = this.coordLast[0].x;
    this.coordLast[1].y = this.coordLast[0].y;
    this.coordLast[0].x = this.x;
    this.coordLast[0].y = this.y;

    if(this.startX >= this.targetX){
      if(this.x + vx <= this.targetX){
        this.x = this.targetX;
        this.hitX = true;
      } else {
        this.x += vx * self.dt;
      }
    } else {
      if(this.x + vx >= this.targetX){
        this.x = this.targetX;
        this.hitX = true;
      } else {
        this.x += vx * self.dt;
      }
    }

    if(this.startY >= this.targetY){
      if(this.y + vy <= this.targetY){
        this.y = this.targetY;
        this.hitY = true;
      } else {
        this.y += vy * self.dt;
      }
    } else {
      if(this.y + vy >= this.targetY){
        this.y = this.targetY;
        this.hitY = true;
      } else {
        this.y += vy * self.dt;
      }
    }				

    if(this.hitX && this.hitY){
      var randExplosion = rand(0, 9);
      self.createParticles(this.targetX, this.targetY, this.color);
      self.fireworks.splice(index, 1);					
    }
  };

  Firework.prototype.draw = function(){
    self.ctx.lineWidth = this.lineWidth;

    var coordRand = (rand(1,3)-1);					
    self.ctx.beginPath();							
    self.ctx.moveTo(Math.round(this.coordLast[coordRand].x), Math.round(this.coordLast[coordRand].y));
    self.ctx.lineTo(Math.round(this.x), Math.round(this.y));
    self.ctx.closePath();
    self.ctx.strokeStyle = this.color;
    self.ctx.stroke();						 
  };

  /*=============================================================================*/	
  /* Update Fireworks
  /*=============================================================================*/		
  self.updateFireworks = function(){
    var i = self.fireworks.length;
    while(i--){
      var f = self.fireworks[i];
      f.update(i);
    };
  };

  /*=============================================================================*/	
  /* Draw Fireworks
        /*=============================================================================*/
  self.drawFireworks = function(){
    var i = self.fireworks.length;			
    while(i--){
      var f = self.fireworks[i];		
      f.draw();
    };
  };

  /*=============================================================================*/	
  /* Clear Canvas
  /*=============================================================================*/
  self.clear = function(){
    self.particles = [];
    self.fireworks = [];
    self.ctx.clearRect(0, 0, self.cw, self.ch);
  };

  /*=============================================================================*/	
  /* Delta
  /*=============================================================================*/
  self.updateDelta = function(){
    var newTime = Date.now();
    self.dt = (newTime - self.oldTime)/16;
    self.dt = (self.dt > 5) ? 5 : self.dt;
    self.oldTime = newTime;	
  }

  /*=============================================================================*/	
  /* Main Loop
  /*=============================================================================*/
  self.canvasLoop = function(){
    requestAnimFrame(self.canvasLoop, self.canvas);
    self.updateDelta();
    self.ctx.globalCompositeOperation = 'destination-out';
    self.ctx.fillStyle = 'rgba(0,0,0,'+self.clearAlpha/100+')';
    self.ctx.fillRect(0,0,self.cw,self.ch);
    self.ctx.globalCompositeOperation = 'lighter';
    self.updateFireworks();
    self.updateParticles();
    self.drawFireworks();			
    self.drawParticles();			
  };

  self.init();

  var isMobile = window.innerWidth < 768;

  // 색상값
  var colors = [
    '#DEAD25',
    '#076369',
    '#EA1917'
  ];

  var countPer = isMobile ? window.innerHeight * 0.015 : window.innerWidth * 0.005; // 디바이스별 갯수
  var heightCutPer = isMobile ? 1.3 : 2;  // 터지는 높이 영역
  var distance = isMobile ? 500 : 500;    // 발사 간격

  var initialLaunchCount = Math.round(countPer * 1); // 최종 갯수
  
  while(initialLaunchCount--){
    setTimeout(function(){
      self.fireworks.push(new Firework(
        rand(150, self.cw - 150), self.ch,                                // 시작 위치
        rand(50, self.cw - 50), rand(100, self.ch / heightCutPer ) - 50,  // 목적 위치
        colors[rand(0, colors.length - 1)],                               // 색상
        1                                                                 // 속도
      ));
    }, initialLaunchCount * distance);
  }
}

var fworks = new Fireworks();